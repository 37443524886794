<template>
  <div class="q-pa-md">
    <div class="text-h6">{{ project_description }}</div>
    <div class="text-subtitle1">Status: {{ partner_description }}</div>
  </div>
</template>

<script setup>
import { computed } from "vue";
import { useStore } from "vuex";

const $store = useStore();

const project_description = computed(
  () => $store.getters.projectSettings?.description ?? ""
);
const partner_description = computed(
  () => $store.getters.status?.Partner_Description ?? ""
);
</script>
